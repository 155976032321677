import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { cartActions } from "../../../../../actions";
import {
  Typography,
  Grid,
  Box,
  Stack,
  Card,
  CardContent,
  IconButton,
  Chip,
  CardMedia,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@mui/material";
// import AddShoppingCartTwoToneIcon from "@mui/icons-material/AddShoppingCartTwoTone";
// import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import SkipNextIcon from "@mui/icons-material/SkipNext";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import DoneIcon from "@mui/icons-material/Done";
import GarmentImage from "../../GarmentImage/GarmentImage.component";
import styles from "./CartItem.module.scss";

const CartItem = (props) => {
  const { item } = props;
  const {
    id,
    name,
    style,
    unitprice,
    itemTotal,
    quantity,
    thumbnail,
    organization_id,
    comments,
    sizeQuantity,
  } = item;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleRemoveFromCart = (item) => {
    setItemToDelete(item);
    setConfirmationOpen(true);
  };

  const confirmDelete = () => {
    if (itemToDelete) {
      dispatch(cartActions.deleteParameter(itemToDelete));
      setItemToDelete(null);
      setConfirmationOpen(false);
      window.location.reload();
    }
  };

  const cancelDelete = () => {
    setItemToDelete(null);
    setConfirmationOpen(false);
  };

  return (
    <>
      <Card sx={{ display: "flex" }}>
        <GarmentImage
          id={id}
          thumbnail={thumbnail}
          organizationId={organization_id}
          padding={2}
          minHeight={250}
          maxWidth={250}
          shadow={false}
        />

        <CardContent>
          <Stack spacing={2}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                  >
                    <Box sx={{ flex: 1 }}>
                      {name && (
                        <Link to={`/store/${id}`} className={styles.itemLink}>
                          <Typography
                            variant="h4"
                            sx={{ fontSize: "1.2rem", fontWeight: 600 }}
                          >
                            {name}
                          </Typography>
                        </Link>
                      )}

                      {style && (
                        <Typography variant="caption">
                          Style #{style}
                        </Typography>
                      )}
                    </Box>

                    <IconButton
                      aria-label="delete"
                      onClick={() => navigate(`/store/${id}`)}
                      sx={{ ml: 2 }}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveFromCart(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography sx={{ mr: 3, fontSize: "1.2rem" }}>
                      {itemTotal && (
                        <>
                          <small>Total: &nbsp;</small>
                          <strong style={{ color: "#512879" }}>
                            ${itemTotal}
                          </strong>
                        </>
                      )}

                      {unitprice && quantity && (
                        <small className={styles.itemTotal}>
                          ${unitprice} * {quantity}
                        </small>
                      )}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container>
                <Grid item xs={6}>
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 600,
                        mb: 1,
                      }}
                    >
                      Sizes:
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {sizeQuantity?.length > 0 &&
                        sizeQuantity.map((item, i) => (
                          <Chip
                            key={`${item?.size}-${i}`}
                            label={`${item?.size?.toUpperCase()}: ${
                              item?.quantity
                            }`}
                            variant="filled"
                            size="small"
                            onClick={() => {
                              return false;
                            }}
                          />
                        ))}
                    </Stack>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 600,
                        mb: 1,
                      }}
                    >
                      Comments:
                    </Typography>
                    <Typography variant="body2">
                      {comments ? comments : "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6} sx={{ pl: 5 }}>
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 600,
                        mb: 1,
                      }}
                    >
                      Names:
                    </Typography>
                    {sizeQuantity?.length > 0 &&
                      sizeQuantity.map((item, i) => (
                        <div key={`${item?.size}-${i}`}>
                          {item?.customizations?.length === 0 ? (
                            <Typography variant="body2">
                              Customize Names & Numbers: <strong>No</strong>
                            </Typography>
                          ) : item?.customizations?.length > 10 ? (
                            <Typography variant="body2">
                              Customize Names & Numbers: Yes
                            </Typography>
                          ) : (
                            <NamesList customizations={item?.customizations} />
                          )}
                        </div>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <Dialog
        open={confirmationOpen}
        onClose={cancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item from your cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            No
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CartItem;

const NamesList = ({ customizations }) => {
  return (
    <Typography variant="body2">
      {customizations?.map((item, i) => (
        <span key={`customization${1}`}>
          {item?.name} {item?.number} <br />
        </span>
      ))}
    </Typography>
  );
};
