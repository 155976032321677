import { getAxiosInstance } from "../axios";
import { getValue, hasPersisted, persistKeys, storeValue } from "../persist";

export class InvoicesService {
  async getInvoiceById(id) {
    try {
      const persistKey = `${persistKeys.invoices}-${id}`;
      if (hasPersisted(persistKey)) {
        return getValue(persistKey);
      }

      const response = await getAxiosInstance().get(`/invoices/${id}`);

      if (response.status === 404) {
        throw new Error("Invoice not found");
      }

      storeValue(persistKey, response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uploadPaymentRecord(file) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await getAxiosInstance().post(
        `/file/account-transactions`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getInvoiceByOrganizationName(organizationName, page) {
    try {
      const response = await getAxiosInstance().get(
        `/invoices?organization.name=${organizationName}&page=${page}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getInvoiceByPOId(production_order_id, page) {
    try {
      const response = await getAxiosInstance().get(
        `/invoices?production_order_id=${production_order_id}&page=${page}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getInvoiceByDates(dates, page) {
    try {
      const response = await getAxiosInstance().get(
        `/invoices?start_date=${dates.start_date}&end_date=${dates.end_date}&page=${page}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getPaginatedInvoicesByStatus(status, page, pages) {
    const key = `quotations-${status}-${page}`;
    try {
      // const persistedValue = getValue(key);
      // if (persistedValue) {
      //   return persistedValue;
      // }

      const processedStatus =
        !status || status === 0 || status === "all" ? "" : `/status/${status}`;
      let path = `invoices${processedStatus}?page=${page}&pages=${pages}`;
      const response = await getAxiosInstance().get(path);
      // storeValue(key, response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
