import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  Table,
  TextField,
  Typography,
  styled,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AcceptQuotationModal from "../Common/Modals/AcceptQuotationModal";
import AdditionalCustomerQuotation from "../Common/Modals/AddtionalCustomerQuotation";
import FailedModal from "../Common/Modals/FailedModal";
import axios from "../API/axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { convertToPlaces } from "../helpers/helpers";
import SuccesModal from "../Common/Modals/SuccessModal";
import { ADD_REQUEST } from "../constants/Constants";
import { apiConstants } from "../constants";
import { authHeader } from "../helpers";
import AlertToast from "../components/common/Toast";
import Intro from "../components/atoms/Intro/Intro.component";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { checkPermission } from "../helpers/helpers";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ProgressCircularWithBackdrop from "../Common/ProgressCircular/ProgressCircularWithBackdrop";
import { VERTICAL } from "../constants/Constants";
import QuotationActivityLogsStepper from "./QuotationActivityLogsStepper";
import { PERMISSIONS } from "../constants/data/rolesPermissions.constants";
import { generatePDF } from "../helpers/pdf-gen";
import AcceptanceTopbanner from "../components/layout/pageLayout/AcceptanceTopbanner/AcceptanceTopbanner";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { countryActions } from "../actions";

const useStyles = makeStyles({
  section: {
    padding: "30px 40px",
  },
  termsAndConditionsHeader: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    marginBottom: "30px !important",
  },
  termsAndConditionRow: {
    display: "flex",
    "& .MuiTypography-root:first-child": {
      textAlign: "right",
      width: "20px",
      marginRight: "9px",
    },
    "& .MuiTypography-root:last-child": {
      textAlign: "left",
      width: "100%",
    },
  },
});

const StyledHeader = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  textAlign: "left",
  height: "93px",
  paddingLeft: "10px",
  paddingTop: "10px",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.neutral.light,
  padding: "20px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.light,
  height: "70px",
}));

const StyledTableCell = styled(TableCell)({
  width: "200px",
  fontWeight: 700,
});

const StyledTypography = styled(Typography)({
  fontWeight: "400",
  fontSize: "14px",
});

const StyledItalic = styled("i")({
  fontSize: "12px",
});

const StyledLinked = styled(Typography)({
  textDecoration: "underline",
  textUnderlineOffset: "0.2em",
  cursor: "pointer",
});

const StyledImageContainer = styled("div")({
  background: "rgba(239, 239, 239, 0.65)",
  border: "2px dashed #9E9E9E",
  borderRadius: "8px",
  width: "100%",
  height: "auto",
});

const AcceptQuotation = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id, admin } = useParams();
  const [data, setData] = useState({});
  const [organizationName, setOrganizationName] = useState("");
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [quotationStatus, setQuotationStatus] = useState("");
  const [countries, setCountries] = useState([]);
  const [success, setSuccess] = useState({
    open: false,
    title: "",
    body: "",
  });
  const [agree, setAgree] = useState(false);
  const [create, setCreate] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [notes, setNotes] = useState("");
  const [openCustomer, updateCustomer] = useState(false);
  const [openAdditional, updateAdditional] = useState(false);
  const [rejectionNotes, setRejectionNote] = useState("");
  const [file, setFile] = useState([]);
  const [totalDelivery, setTotalDelivery] = React.useState(0);
  const [quotationActivityLogs, setQuotationActivityLogs] = React.useState([]);
  const [salesAgentData, setSalesAgentData] = React.useState([]);

  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => setToastState({ open: false });
  const showEditQuotation = checkPermission(PERMISSIONS.QUOTATIONS);
  const [error, setError] = React.useState({
    value: false,
    name: "",
    message: "",
  });
  const [openProgressCircular, setOpenProgressCircular] = React.useState(false);

  const downloadableElement = React.createRef();
  const [acceptModal, setAcceptModal] = useState(false);

  const [org, setOrg] = useState({});

  let breadcrumbs = [];
  if (admin) {
    breadcrumbs = [
      <Link underline="hover" key="1" color="inherit" to="/admin/quotations">
        All Quotations
      </Link>,
      <Typography key="3">{"View Quotation"}</Typography>,
    ];
  }

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(`${apiConstants.API_URL}/accept-quotations/${id}`, requestOptions)
      .then((response) => {
        response.text().then((text) => {
          if (response.ok) {
            const data = JSON.parse(text);
            const {
              custom_items,
              items,

              organization_name,
              organization_email,
              contact_no,
              organization_address_line_1,
              organization_address_line_2,
              postal_code,
              country,

              quotation_status,
              rejected_reason,
              quotation_files,
              estimated_shipping_charges,
              sales_agent,
            } = data?.data;

            setOrg({
              organization_name,
              organization_email,
              contact_no,
              organization_address_line_1,
              organization_address_line_2,
              postal_code,
              country,
            });

            setTotalDelivery(estimated_shipping_charges);

            setData(data?.data);
            setSalesAgentData(sales_agent);
            setOrganizationName(organization_name);
            setOrganizationEmail(organization_email);
            setQuotationStatus(quotation_status?.keyword);
            const quotationFiles = quotation_files.map((f) => {
              f.name = f.file.split("/")[2].split("_")[1];
              return f;
            });
            setFile(quotationFiles);
            rejected_reason && setRejectionNote(rejected_reason);
            getQuotationActivityLog();
          }
        });
      })
      .catch();

    fetch(`${apiConstants.API_URL}/countries`, requestOptions)
      .then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setCountries(data?.data);
        });
      })
      .catch();
  }, [id]);

  const getQuotationActivityLog = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/activity-logs/type/Quotation/id/${id}`,
      requestOptions
    )
      .then((response) => {
        response.text().then((text) => {
          if (response.ok) {
            const data = text && JSON.parse(text);
            console.log(data?.data);
            setQuotationActivityLogs(data?.data);
          }
        });
      })
      .catch((e) => console.log(e.message));
  };

  const acceptAction = () => {
    // if(quotationStatus === 'accepted'){
    //   setToastMessage(`The Quotation number #${id} is already been accepted`);
    //   setToastState({
    //     open:true, vertical:'top', horizontal:'right'
    //   });
    // }
    // else{
    if (create) {
      updateCustomer(!openCustomer);
    } else {
      setAcceptModal(true);
    }
    // }
  };

  const checkBoxHandle = (e) => {
    const { id, checked } = e.target;
    if (id === "create") {
      setCreate(() => checked);
    } else {
      setAgree(() => checked);
    }
  };

  const {
    gst,
    totalPrice,
    originalTotal,
    extraCharges,
    totalDiscount,
    grandTotal,
  } = useMemo(() => {
    if (data?.items?.length) {
      const a = data.items.reduce(
        (pre, item, index) => {
          return {
            gst: pre.gst + item?.gst_amount || 0,
            totalPrice: pre.totalPrice + item?.total_price || 0,
            originalTotal: pre.originalTotal + item?.original_total_price || 0,
            extraCharges: pre.extraCharges + item?.extra_charges || 0,
          };
        },
        {
          gst: 0,
          totalPrice: 0,
          originalTotal: 0,
          extraCharges: 0,
        }
      );

      const totalDiscount = a.originalTotal - a.totalPrice;

      return {
        ...a,
        totalDiscount,
        grandTotal:
          a.gst + a.totalPrice + a.extraCharges + totalDelivery - totalDiscount,
      };
    }
    return {
      gst: 0,
      totalPrice: 0,
      originalTotal: 0,
      extraCharges: 0,
      totalDiscount: 0,
      grandTotal: 0,
    };
  }, [data]);

  const request = (type, notes = "", user = {}) => {
    if (type === "reject") {
      if (notes !== "") {
        submitAcceptQuotation(type, notes, user);
      } else {
        setError({ value: true, name: "", message: "Reason is required" });
      }
    } else {
      submitAcceptQuotation(type, notes, user);
    }
  };

  const submitAcceptQuotation = (type, notes = "", user = {}) => {
    setOpenProgressCircular(true);
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({ note: notes, user }),
    };

    fetch(
      `${apiConstants.API_URL}/customer/quotations/${id}/${type}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if (response.status === 401) {
            // logout()
          }
          const error = (data && data.error) || response.statusText;
          updateAdditional(false);
          setOpenProgressCircular(false);
          setToastMessage(error);
          setToastState({
            open: true,
            vertical: "top",
            horizontal: "right",
          });
        } else {
          if (type === "accept") {
            setSuccess({
              open: true,
              title: "Quotation is Accepted",
              body: `Quotation #${id} is accepted and we are ready to get started. A S-Trend team member will contact you and guide you through the process. Quotation will send you to this email ${organizationEmail}. Thank you for your order.`,
            });
          } else {
            setSuccess({
              open: true,
              title: "Quotation has been Rejected",
              body: "Quotation Has been Rejected Successfully",
            });
            updateAdditional(false);
          }
          updateCustomer(false);
          setAgree(false);
          setOpenProgressCircular(false);
        }
      });
    });
  };

  const createAccount = (e) => {
    axios.post("/organizations", e).then((res) => {
      request("accept");
      updateCustomer(false);
    });
  };

  const addMessage = (type, message) => {
    setNotes(() => message);
    setAddNote(false);
  };

  const downloadQuotation = React.useCallback(() => {
    generatePDF(downloadableElement.current, "quotation.pdf");
  }, [downloadableElement]);

  const downloadUsingAnchorElement = async (index) => {
    const anchor = document.createElement("a");
    anchor.href = file[index].file_url;
    anchor.download = "*";
    document.body.appendChild(anchor);
    anchor.click();
  };

  const handleEditQuotation = () => {
    navigate(`/admin/quotations/${id}/edit`);
  };

  return (
    <>
      {admin ? (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ pr: 2 }}
        >
          <Intro
            pageTitle={"View Quotation"}
            pageTitleShort={"View Quotation"}
            breadcrumbs={breadcrumbs}
            additionalWidgets=""
          />
          {showEditQuotation && quotationStatus === "rejected" && (
            <Button
              variant="contained"
              onClick={handleEditQuotation}
              sx={{ height: "46.67px" }}
            >
              <EditIcon sx={{ mr: 1 }} />
              Edit quotation
            </Button>
          )}
        </Stack>
      ) : (
        <>
          <AcceptanceTopbanner
            view={"sales-quotation"}
            data={data.length > 0 ? data[0] : {}}
            organizationName={organizationName}
            organizationEmail={organizationEmail}
            salesAgent={salesAgentData}
          />
          <Box
            sx={{
              padding: "40px 0",
              maxWidth: "1280px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Box sx={{ display: "flex" }}>
                <Typography component="span">Dear </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    paddingLeft: "3px",
                    textTransform: "capitalize",
                  }}
                >
                  {organizationName ? (
                    organizationName + ","
                  ) : (
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "14px",
                        background: "#bbb",
                        width: "150px",
                      }}
                    />
                  )}
                </Typography>
              </Box>
              <Button variant="contained" onClick={() => downloadQuotation()}>
                Download Quotation
              </Button>
            </Stack>
            <Box sx={{ padding: "0 0 10px" }}>
              <Typography sx={{ padding: "10px 0" }}>
                Thank you for your interest in our products - the team are also
                incredibly excited to get started on this opportunity. We have
                attached a quote for your consideration. As our prices are
                dictated by the quantity ordered, please let us know if you wish
                to alter the quantities we have provided in the quote. If you
                are happy to move forward, please accept this Quote and we will
                get to work! Alternatively, please do not hesitate to get in
                touch if you have any questions or concerns.
              </Typography>
              <Typography>Best wishes,</Typography>
              <Typography>Team S-Trend</Typography>
            </Box>
          </Box>
        </>
      )}

      <div ref={downloadableElement}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            padding: admin ? 0 : "40px 25px 40px 40px;",
            backgroundColor: admin ? "#fff" : "#F4F4F4",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              columnGap: "20px",
              maxWidth: "1280px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div>
              <Card style={{ minWidth: "500px" }}>
                <Stack direction={"column"} justifyContent="center">
                  <StyledHeader>
                    <Typography fontSize={"24px"}>Your Products</Typography>
                    <Typography>
                      Please note all prices are exclude GST
                    </Typography>
                  </StyledHeader>
                  <Box>
                    <Table>
                      <TableHead>
                        <TableRow style={{ height: "56px" }}>
                          <StyledTableCell>Description</StyledTableCell>
                          <StyledTableCell align="right">
                            Unit Price
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Quantity
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Style Name
                          </StyledTableCell>
                          <StyledTableCell align="right">Price</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.items?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {item?.name
                                ? item?.name
                                : `${item?.price?.item.name} - ${item?.price?.fabric.name}`}
                            </TableCell>
                            <TableCell align="right">
                              {convertToPlaces(item?.unit_price)}
                            </TableCell>
                            <TableCell align="right">{item?.qty}</TableCell>
                            <TableCell align="right">{item?.style}</TableCell>
                            <TableCell align="right">
                              {convertToPlaces(item?.total_price)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Stack>
              </Card>
              <Box>
                {file.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        marginTop: "30px",
                        marginBottom: "20px",
                      }}
                    >
                      Concept Design
                    </Typography>
                    <StyledImageContainer>
                      {file?.map((i, index) => (
                        <Box
                          key={i?.id}
                          sx={{
                            display: "flex",
                            padding: "5px",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <InsertDriveFileIcon
                              color="primary"
                              sx={{ marginRight: "5px" }}
                            />
                            <Typography variant="span">{i?.name}</Typography>
                          </Box>
                          <Button
                            onClick={() => downloadUsingAnchorElement(index)}
                          >
                            Download File
                          </Button>
                        </Box>
                      ))}
                    </StyledImageContainer>
                  </>
                )}
              </Box>
            </div>
            <div>
              <Stack
                direction={"column"}
                style={{ minWidth: "375px" }}
                spacing={3}
              >
                <StyledCard>
                  <Stack textAlign={"left"} spacing={3}>
                    <Typography fontSize={"20px"}>Your Quote</Typography>
                    <Stack spacing={1}>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <StyledTypography>Add-ons</StyledTypography>
                        <StyledTypography>
                          {convertToPlaces(extraCharges)}
                        </StyledTypography>
                      </Stack>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <StyledTypography>Discounts applied</StyledTypography>
                        <StyledTypography>
                          {convertToPlaces(totalDiscount)}
                        </StyledTypography>
                      </Stack>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <StyledTypography>Total Excluding Tax</StyledTypography>
                        <StyledTypography>
                          {convertToPlaces(totalPrice)}
                        </StyledTypography>
                      </Stack>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <StyledTypography>Delivery</StyledTypography>
                        <StyledTypography>
                          {convertToPlaces(totalDelivery)}
                        </StyledTypography>
                      </Stack>
                      <Divider style={{ backgroundColor: "#E0E0E0" }} />
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <StyledTypography>GST</StyledTypography>
                        <StyledTypography>
                          {convertToPlaces(gst)}
                        </StyledTypography>
                      </Stack>
                      <Divider style={{ backgroundColor: "#E0E0E0" }} />
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <StyledTypography>Total</StyledTypography>
                        <StyledTypography>
                          {convertToPlaces(grandTotal)}
                        </StyledTypography>
                      </Stack>
                    </Stack>
                    {/* <Stack direction={"row"} spacing={2}>
                      <ErrorOutlineIcon />
                      <StyledTypography paragraph fontSize={"13px"}>
                        <StyledItalic>
                          If you’d like to proceed with this order, simply
                          ‘Accept’ and we’ll get started! You can add additional
                          request by clicking
                          <StyledLinked onClick={() => setAddNote(true)}>
                            hear
                          </StyledLinked>
                        </StyledItalic>
                      </StyledTypography>
                    </Stack> */}
                  </Stack>
                </StyledCard>
                {admin && quotationStatus === "rejected" && (
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: "red",
                        marginBottom: "10px",
                      }}
                    >
                      Reason to reject quotation
                    </Typography>
                    <Typography>{rejectionNotes}</Typography>
                  </Box>
                )}
                {admin && (
                  <Stack>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        marginTop: "30px",
                        marginBottom: "20px",
                      }}
                    >
                      Quotation Activity logs
                    </Typography>
                    <QuotationActivityLogsStepper
                      activeStep={quotationActivityLogs.length - 1}
                      orientation={VERTICAL}
                      steps={quotationActivityLogs}
                    />
                  </Stack>
                )}
                {!admin && quotationStatus === "submitted" && (
                  <>
                    <StyledButton
                      color="success"
                      variant="contained"
                      onClick={() => acceptAction()}
                      disabled={!agree}
                    >
                      Accept Quotation
                    </StyledButton>
                    <Stack textAlign={"left"} spacing={1}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox onClick={(e) => checkBoxHandle(e)} />
                          }
                          label="By checking this box, you are agreeing to our terms and conditions"
                        />
                      </FormGroup>
                      {/* <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox onClick={(`e) => checkBoxHandle(e)} id="create" />
                      }
                      label="create a new customer when accepting the quotation"
                    />
                  </FormGroup> */}
                    </Stack>
                    <Divider>
                      <Typography b>OR</Typography>
                    </Divider>
                    <Button
                      color="error"
                      variant="outlined"
                      style={{ height: "45px" }}
                      onClick={() => {
                        if (quotationStatus === "rejected") {
                          setToastMessage(
                            `The Quotation number #${id} is already been Rejected`
                          );
                          setToastState({
                            open: true,
                            vertical: "top",
                            horizontal: "right",
                          });
                        } else {
                          updateAdditional(true);
                        }
                      }}
                    >
                      Request Change
                    </Button>
                  </>
                )}
              </Stack>
            </div>
          </div>
          <AcceptQuotationModal
            open={openCustomer}
            handleClose={acceptAction}
            countries={countries}
            id={id}
            request={request}
            createAccount={createAccount}
          />
          <AdditionalCustomerQuotation
            open={openAdditional}
            handleClose={updateAdditional}
            request={request}
            error={error}
          />
          <AdditionalCustomerQuotation
            open={addNote}
            handleClose={setAddNote}
            request={addMessage}
            type={ADD_REQUEST}
            error={error}
          />
          <FailedModal />
          <SuccesModal {...success} handleClose={setSuccess} />
          <AlertToast
            toastMessage={toastMessage}
            toastState={toastState}
            handleClose={handleToastClose}
          />
        </Stack>
        {!admin && (
          <Box
            className={classes.section}
            style={{
              maxWidth: "1280px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography className={classes.termsAndConditionsHeader}>
              TERMS AND CONDITIONS
            </Typography>
            <Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>1.</Typography>
                <Typography>
                  Our delivery timeframe is 4 - 6 weeks, from artwork approval,
                  with the exception of some accessories. Please communicate any
                  delivery deadlines in advance, if applicable.
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>2.</Typography>
                <Typography>All unit prices are exclusive of GST;</Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>3.</Typography>
                <Typography>
                  Payments made by credit card will incur a 2% surcharge on
                  Grand Total;
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>4.</Typography>
                <Typography>
                  50% deposit is required prior to commencement of order and the
                  balance is due prior to delivery;
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>5.</Typography>
                <Typography>
                  Repeat orders of existing styles in quantities of 1 to 4 are
                  subject to a small quantity surcharge of $2.00 per garment;
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>6.</Typography>
                <Typography>
                  Prices are inclusive of artwork with 3 revisions on orders
                  with 16 or more units per style. Artwork for orders of under
                  16 units per style is subject to a designer's fee of $50.00
                  per garment style. 50% of the designer's fee is deducted from
                  order invoice if an order of more than 4 units is placed.
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>7.</Typography>
                <Typography>
                  Logos not provided in correct vector formats (.ai .pdf .eps)
                  are subject to a redraw fee of $20.00 per logo.
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>8.</Typography>
                <Typography>
                  Names and numbers are subject to an additional $1.00 per unit.
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>9.</Typography>
                <Typography>
                  Our standard lead time is 4 weeks for production. Express
                  service is available from time to time. Please check with your
                  S-Trend representative for further information.
                </Typography>
              </Box>
              <Box className={classes.termsAndConditionRow}>
                <Typography>10.</Typography>
                <Typography>
                  Pre-production samples on new orders are provided free for
                  confirmed orders of 150 units or above. Additional charges
                  apply for pre-production samples for orders of under 150
                  units. Please check with your representative for further
                  information.
                </Typography>
              </Box>
            </Box>
            <ProgressCircularWithBackdrop open={openProgressCircular} />
          </Box>
        )}
        <AcceptQuotationWithOrgInfoModal
          isOpen={acceptModal}
          setOpen={setAcceptModal}
          onSubmit={(user) => request("accept", notes, user)}
          org={org}
        />
      </div>
    </>
  );
};

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  maxHeight: "100%",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxHeight: "75%",
  overflowY: "scroll",
};

const AcceptQuotationWithOrgInfoModal = ({
  isOpen = true,
  org,
  setOpen,
  onClose,
  onSubmit,
}) => {
  const countryState = useSelector((state) => state.countries);
  const dispatch = useDispatch();
  const [addOrg, setAddOrg] = React.useState(false);
  const [useBillingForShipping, setUseBillingForShipping] =
    React.useState(true);

  React.useEffect(() => {
    dispatch(countryActions.getAllParameters());
  }, []);

  console.log({ org });

  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <Stack
          direction={"column"}
          spacing={2}
          sx={{ padding: "0 15px 15px 15px" }}
        >
          <Typography fontSize={"24px"}>Accept Quotation</Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addOrg}
                  onChange={(e) => {
                    setAddOrg(e.target.checked);
                  }}
                  id="use_same_billing_address"
                  name="use_same_billing_address"
                />
              }
              label="Add Organization Information"
            />
          </FormGroup>

          {
            <>
              <Formik
                initialValues={{
                  name: org.organization_name,
                  email: org.organization_email,
                  phone: org.contact_no,
                  contact_person_name: "",
                  address_line_1: org.organization_address_line_1,
                  address_line_2: org.organization_address_line_2,
                  suburb: "",
                  state: "",
                  postcode: org.postal_code,
                  country_id: org.country?.id,

                  delivery_contact_person_name: org.organization_name,
                  delivery_address_line_2: org.organization_address_line_2,
                  delivery_address_line_1: org.organization_address_line_1,
                  delivery_suburb: "",
                  delivery_state: "",
                  delivery_postcode: "",
                  delivery_country_id: org.country?.id,
                }}
                // validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => {
                  // handleSubmit(values);
                  // resetForm();
                  // TODO: update organization details
                  onSubmit({
                    organization_email: values.email,
                    organization_address_line_1: values.address_line_1,
                    organization_address_line_2: values.address_line_2,
                    postal_code: values.postcode,
                    contact_no: values.phone,
                    country_id: values.country_id,
                    use_same_for_billing_address: useBillingForShipping,
                  });
                }}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  isValid,
                  setFieldValue,
                  handleSubmit,
                }) => (
                  <>
                    {addOrg && (
                      <>
                        <Typography variant="h6">
                          Add Organization Information
                        </Typography>

                        <Grid
                          container
                          spacing={{ xs: 2, sm: 2 }}
                          sx={{ pt: 0, pb: 0 }}
                        >
                          <Grid item xs={12} md={12}>
                            <Typography fontSize={"20px"}>
                              Billing Details
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="name"
                              name="name"
                              label="Organization Name"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("name", e.target.value);
                              }}
                              defaultValue={values.name}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="email"
                              name="email"
                              label="Email Address"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("email", e.target.value);
                              }}
                              defaultValue={values.email}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="phone"
                              name="phone"
                              label="Phone Number"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("phone", e.target.value);
                              }}
                              defaultValue={values.phone}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="contact_person_name"
                              name="contact_person_name"
                              label="Contact Person Name"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue(
                                  "contact_person_name",
                                  e.target.value
                                );
                              }}
                              defaultValue={values.contact_person_name}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="address_line_1"
                              name="address_line_1"
                              label="Address Line 1"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("address_line_1", e.target.value);
                              }}
                              defaultValue={values.address_line_1}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="address_line_2"
                              name="address_line_2"
                              label="Address Line 2"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("address_line_2", e.target.value);
                              }}
                              defaultValue={values.address_line_2}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="suburb"
                              name="suburb"
                              label="Suburb"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("suburb", e.target.value);
                              }}
                              defaultValue={values.suburb}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="state"
                              name="state"
                              label="State"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("state", e.target.value);
                              }}
                              defaultValue={values.state}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              id="postcode"
                              name="postcode"
                              label="Postcode"
                              onBlur={handleBlur}
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                setFieldValue("postcode", e.target.value);
                              }}
                              defaultValue={values.postcode}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl
                              fullWidth
                              error={
                                touched.country_id && Boolean(errors.country_id)
                              }
                              sx={{ height: 40 }}
                            >
                              <InputLabel sx={{ ml: -1.75 }}>
                                Country
                              </InputLabel>
                              {countryState?.loading && (
                                <CircularProgress
                                  size={20}
                                  sx={{
                                    position: "absolute",
                                    right: 25,
                                    bottom: 10,
                                  }}
                                />
                              )}
                              <Select
                                fullWidth
                                id="country_id"
                                name="country_id"
                                label="Country"
                                onChange={(e) => {
                                  setFieldValue("country_id", e.target.value);
                                }}
                                onBlur={handleBlur}
                                variant="standard"
                                sx={{ height: 40 }}
                                defaultValue={values.country_id}
                                // size="small"
                              >
                                {countryState?.items?.data.map((item) => (
                                  <MenuItem key={item?.id} value={item.id}>
                                    {item?.name} ({item?.code})
                                  </MenuItem>
                                ))}
                              </Select>
                              {touched.country_id &&
                                Boolean(errors.country_id) && (
                                  <FormHelperText>
                                    {touched.country_id && errors.country_id}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={useBillingForShipping}
                                    onChange={(e) => {
                                      setUseBillingForShipping(
                                        e.target.checked
                                      );
                                    }}
                                    id="use_same_billing_address"
                                    name="use_same_billing_address"
                                  />
                                }
                                label="Use Same Billing Address for Shipping"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {!!!useBillingForShipping && (
                          <Grid
                            container
                            spacing={{ xs: 2, sm: 2 }}
                            sx={{ pt: 0, pb: 0 }}
                          >
                            <Grid item xs={12} md={12}>
                              <Typography fontSize={"20px"}>
                                Shipping Details
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_name"
                                name="delivery_name"
                                label="Delivery Name"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_email"
                                name="delivery_email"
                                label="Delivery Email Address"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_phone"
                                name="delivery_phone"
                                label="Delivery Phone Number"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_contact_person_name"
                                name="delivery_contact_person_name"
                                label="Delivery Contact Person Name"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_address_line_1"
                                name="delivery_address_line_1"
                                label="Delivery Address Line 1"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_address_line_2"
                                name="delivery_address_line_2"
                                label="Delivery Address Line 2"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_suburb"
                                name="delivery_suburb"
                                label="Delivery Suburb"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_state"
                                name="delivery_state"
                                label="Delivery State"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="delivery_postcode"
                                name="delivery_postcode"
                                label="Delivery Postcode"
                                onBlur={handleBlur}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="standard"
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl
                                fullWidth
                                error={
                                  touched.country_id &&
                                  Boolean(errors.country_id)
                                }
                                sx={{ height: 40 }}
                              >
                                <InputLabel sx={{ ml: -1.75 }}>
                                  Country
                                </InputLabel>
                                {countryState?.loading && (
                                  <CircularProgress
                                    size={20}
                                    sx={{
                                      position: "absolute",
                                      right: 25,
                                      bottom: 10,
                                    }}
                                  />
                                )}
                                <Select
                                  fullWidth
                                  id="delivery_country_id"
                                  name="delivery_country_id"
                                  label="Country"
                                  onChange={(e) => {}}
                                  onBlur={handleBlur}
                                  variant="standard"
                                  sx={{ height: 40 }}
                                  // size="small"
                                >
                                  {countryState?.items?.data.map((item) => (
                                    <MenuItem key={item?.id} value={item.id}>
                                      {item?.name} ({item?.code})
                                    </MenuItem>
                                  ))}
                                </Select>
                                {touched.country_id &&
                                  Boolean(errors.country_id) && (
                                    <FormHelperText>
                                      {touched.country_id && errors.country_id}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}

                    <Stack
                      sx={{ paddingX: 2 }}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          setOpen(false);
                          handleSubmit();
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </>
                )}
              </Formik>
            </>
          }
        </Stack>
      </Box>
    </Modal>
  );
};

export default AcceptQuotation;
