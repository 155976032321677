import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { VERTICAL } from "../../constants/Constants";
import ProductionStatusStepper from "../../components/ProductionOrder/ProductionStatusStepper";

const StyledButton = styled(Button)({
  color: "white",
});

const StyledPriceButton = styled(Button)({
  color: "white",
  height: "40px",
});

const style = {
  background: "#FFFFFF",
  boxShadow:
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  borderRadius: "4px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "798px",
  minHeight: "174px",
  padding: "20px",
};

const ProductionOrderUpdateStatusModal = ({ ...props }) => {
  const {
    open,
    handleClose,
    id,
    item,
    stages,
    handleStoreStages,
    sales_order_item_passed_stages,
    activeStep,
  } = props;

  const [error, setError] = useState({ value: false, name: "", message: "" });

  const [stage, setStage] = React.useState({});

  const onChange = (event) => {
    const { value } = event.target;
    setStage(value);
  };

  useEffect(() => {}, []);

  const clearAll = () => {
    handleClose();
  };

  const handleUpdateStatus = () => {
    handleStoreStages(stage);
  };

  return (
    <Modal
      open={open}
      onClose={clearAll}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={1}>
            <Typography
              id="modal-modal-title"
              fontWeight={500}
              fontSize={"20px"}
            >
              Production Order #{id} - {item}
            </Typography>
          </Stack>
          <Stack
            sx={{ backgroundColor: "#e3e3e3", padding: "15px" }}
            direction={"row"}
            justifyContent={"space-between"}
            spacing={1}
          >
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">
                Production Stage
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="item"
                size="small"
                onChange={onChange}
              >
                {stages?.map((d, index) => (
                  <MenuItem key={index} value={d}>
                    {d?.name}
                  </MenuItem>
                ))}
              </Select>
              {error.value && error.name === "item" && (
                <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>

            <Button
              variant="contained"
              onClick={handleUpdateStatus}
              sx={{
                fontWeight: 500,
                fontSize: 12,
                color: "#fff",
                background: "#12830b",
              }}
            >
              Update status 更新状态
            </Button>
          </Stack>
          {sales_order_item_passed_stages?.length !== 0 && (
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <ProductionStatusStepper
                activeStep={activeStep}
                orientation={VERTICAL}
                steps={sales_order_item_passed_stages}
                alternativeLabel={false}
              />
            </Stack>
          )}

          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button color="success" onClick={() => clearAll()}>
              CLOSE
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

ProductionOrderUpdateStatusModal.prototype = {
  open: PropTypes.bool,

  handleClose: PropTypes.func.isRequired,
};
ProductionOrderUpdateStatusModal.defaultProps = {
  open: false,
};

export default ProductionOrderUpdateStatusModal;
