import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Button, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { convertToPlaces } from "../../../helpers/helpers";
import { authHeader } from "../../../helpers";
import { apiConstants } from "../../../constants";
import { FilterAlt } from "@mui/icons-material";
import ViewTrackingDetails from "../../../Common/ViewTrackingDetails";
import { useDispatch } from "react-redux";
import { couriersActions } from "../../../actions";
import CustomerStatusChange from "../CustomerStatusChange";
import CustomerActionsButton from "../CustomerActionsButton";

const useStyles = makeStyles({
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
  filterButton: {
    color: "#fff !important",
    height: "42px",
    width: "30px",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#512879 !important",
      border: "1px solid #512879",
    },
  },
  dataGrid: {
    "& .MuiTablePagination-displayedRows": {
      fontSize: 12,
    },
    "& .MuiTablePagination-select": {
      fontSize: 12,
    },
    "& .MuiTablePagination-selectLabel": {
      fontSize: 12,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
  },
  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
    marginBottom: "5px !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const salesOrderIds = (params) => {
  const salesOrderIds = params?.row?.sales_order_items.map((item, index) => {
    return item?.sales_order_id;
  });
  const uniqIds = [...new Set(salesOrderIds)];
  return (
    <>
      {uniqIds.map((id, index) => (
        <>
          <div>{id}</div>
          {uniqIds.length - 1 !== index && <span>,</span>}
        </>
      ))}
    </>
  );
};

const productionOrderIds = (params) => {
  const ids = params?.row?.sales_order_items?.map((item) => {
    if (
      item?.production_order_id !== undefined ||
      item?.production_order_id !== null
    ) {
      return item?.production_order_id;
    }
  });
  const uniqIds = [...new Set(ids)];
  return (
    <>
      {uniqIds?.length !== 0 ? (
        uniqIds.map((id, index) => (
          <>
            <div>{id}</div>
            {uniqIds.length - 1 !== index && <span>,</span>}
          </>
        ))
      ) : (
        <div>-</div>
      )}
    </>
  );
};

const StyledInputBase = styled(InputBase)(() => ({
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em)`,
    height: "38px",
    minWidth: "100px",
  },
}));

const AllCustomerInvoices = ({ ...props }) => {
  const { invoices, status, paginationData, handlePageChange } = props;

  const columns = [
    { field: "id", headerName: "Invoice ID", resizable: false },
    {
      field: "created_at",
      headerName: "Date",
      width: 150,
      renderCell: (params) =>
        moment(params.row.created_at).format("DD/MM/YYYY"),
    },
    {
      field: "sales_order_id",
      headerName: "Sales Order ID",
      renderCell: (params) => salesOrderIds(params),
    },
    {
      field: "production_order_id",
      headerName: "Production Order ID",
      minWidth: 150,
      renderCell: (params) => productionOrderIds(params),
    },
    {
      field: "invoice_courier_details",
      headerName: "Tracking Number",
      width: 300,
      renderCell: (params) => (
        <ViewTrackingDetails
          {...params}
          column={"trackingNumber"}
          handlePageChange={handlePageChange}
          paginationData={paginationData}
        />
      ),
    },
    {
      field: "destination",
      headerName: "Destination",
      minWidth: 150,
      renderCell: (params) => (
        <ViewTrackingDetails {...params} column={"destination"} />
      ),
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      sortable: true,
      minWidth: 150,
      valueGetter: (params) => `${params.row.organization?.name || ""}`,
    },
    {
      field: "final_grand_total",
      headerName: "Balance Outstanding",
      sortable: true,
      minWidth: 150,
      valueGetter: (params) =>
        `${convertToPlaces(params.row.balance_amount) || ""}`,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <CustomerStatusChange {...params} component="invoice" />
      ),
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      type: "actions",
      renderCell: (params) => (
        <CustomerActionsButton {...params} component="customerInvoice" />
      ),
      resizable: false,
    },
  ];

  const dispatch = useDispatch();

  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [filteredResults, setFilteredResults] = React.useState([]);

  const [searchCustomer, setSearchCustomer] = React.useState("");
  const [filterSalesOrderById, setFilterSalesOrderById] = React.useState("");
  const [filterInvoiceId, setFilterInvoiceId] = React.useState("");

  React.useEffect(() => {
    if (status === 0) {
      setRows(invoices);
      setFilteredResults(invoices);
    }
    if (searchCustomer === "") {
      setFilteredResults(invoices);
    }
    if (filterInvoiceId === "") {
      setFilteredResults(invoices);
    }
  }, [status, invoices, searchCustomer, filterInvoiceId]);

  React.useEffect(() => {
    dispatch(couriersActions.getAllParameters());
  }, [dispatch]);

  const getInvoicesByOrganization = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/invoices?organization.name=${searchCustomer}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFilteredResults(data?.data);
      });
    });
  };

  const getInvoicesByInvoiceId = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/invoices?id=${filterInvoiceId}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFilteredResults(data?.data);
      });
    });
  };

  const handleCustomerFilter = () => {
    setFilterInvoiceId("");
    if (searchCustomer !== "") {
      getInvoicesByOrganization();
    } else {
      setFilteredResults(rows);
    }
  };

  const handleInvoiceIdFilter = () => {
    setSearchCustomer("");
    setFilterSalesOrderById("");
    if (filterInvoiceId !== "") {
      getInvoicesByInvoiceId();
    } else {
      setFilteredResults(rows);
    }
  };

  return (
    <>
      <Box className={classes.salesOrdersFilterBar}>
        <Typography component={"div"} className={classes.salesOrderFilterTitle}>
          Filter Invoices
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box className={classes.salesOrderFilterContainer}>
            <Box>
              <StyledInputBase
                placeholder="Customer Name"
                inputProps={{ "aria-label": "search", type: "text" }}
                onChange={(e) => {
                  setSearchCustomer(e.target.value);
                }}
                value={searchCustomer}
                onClick={() => {
                  setFilterSalesOrderById("");
                  setFilterInvoiceId("");
                }}
              />
            </Box>
            <Button
              variant="contained"
              className={classes.filterButton}
              onClick={handleCustomerFilter}
            >
              <FilterAlt fontSize="12" />
            </Button>
          </Box>
          <Box className={classes.salesOrderFilterContainer}>
            <Box>
              <StyledInputBase
                placeholder="Invoice ID"
                inputProps={{ "aria-label": "search", type: "text" }}
                onChange={(e) => {
                  setFilterInvoiceId(e.target.value);
                }}
                value={filterInvoiceId}
                onClick={() => {
                  setSearchCustomer("");
                  setFilterSalesOrderById("");
                }}
              />
            </Box>
            <Button
              variant="contained"
              className={classes.filterButton}
              onClick={handleInvoiceIdFilter}
            >
              <FilterAlt fontSize="12" />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box style={{ width: "100%" }}>
        <DataGrid
          autoHeight={true}
          className={classes.dataGrid}
          rows={filteredResults}
          pageSize={paginationData?.per_page}
          pagination
          getRowId={(row) => row.id}
          checkboxSelection
          rowCount={paginationData?.total}
          onPageChange={handlePageChange}
          columns={columns}
          paginationMode="server"
        />
      </Box>
    </>
  );
};

export default AllCustomerInvoices;
