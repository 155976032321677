import * as React from "react";
import { Box, Typography, AppBar, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import AllCustomerSalesOrders from "./AllCustomerSalesOrders";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 35px",
  },
  headerContainerMainTitle: {
    fontWeight: 500,
    fontSize: 34,
    lineHeight: "40.8px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  headerContainerSubTitle: {
    fontSize: 16,
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  headerContainerSubTitleIcon: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0 14.9px",
  },
  headerButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  printButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#F5F5F5 !important",
    borderColor: "#d2d0d0 !important",
  },
  downloadButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#E0E0E0 !important",
  },
  tabs: {
    background: "#fff",
    color: "rgba(0, 0, 0, 0.6)",
    borderRadius: "4px",
    fontWeight: 700,
    "& .MuiTab-root.Mui-selected": {
      borderBottom: "1px solid #512879",
      color: "#512879",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0.125 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SalesOrdersCustomerDetails = ({ ...props }) => {
  const {
    salesOrders,
    handleSalesOrderTab,
    pendingSalesOrders,
    approvedSalesOrders,
    paidSalesOrders,
    paginationData,
    handlePageChange,
    setStatus,
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const switchResult = (status) => {
      switch (status) {
        case 0:
          return "all";
        case 1:
          return "pending";
        case 2:
          return "approved";
        // case 3:
        //   return "paid";
        default:
          return "all";
      }
    };
    const result = switchResult(newValue);
    handleSalesOrderTab(result);
    setStatus(result);
  };

  return (
    <>
      <AppBar position="static" sx={{ boxShadow: "none", borderRadius: "4px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          <Tab label="All Sales Orders" {...a11yProps(0)} sx={{ opacity: 1 }} />
          {/* <Tab
            label="sales orders - pending"
            {...a11yProps(1)}
            sx={{ opacity: 1 }}
          />
          <Tab
            label="sales orders - approved"
            {...a11yProps(2)}
            sx={{ opacity: 1 }}
          /> */}
          {/* <Tab
            label="sales orders - complete"
            {...a11yProps(3)}
            sx={{ opacity: 1 }}
          /> */}
        </Tabs>
      </AppBar>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
      >
        {[0, 1, 2, 3].map((item, index) => (
          <TabPanel
            key={index}
            value={value}
            index={item}
            dir={theme.direction}
          >
            <AllCustomerSalesOrders
              salesOrders={salesOrders}
              pendingSalesOrders={pendingSalesOrders}
              approvedSalesOrders={approvedSalesOrders}
              paidSalesOrders={paidSalesOrders}
              status={value}
              paginationData={paginationData}
              handlePageChange={handlePageChange}
            />
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
};
export default SalesOrdersCustomerDetails;
