import React from "react";
import { AppBar, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles1 = makeStyles({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px 0 35px",
  },
  headerContainerMainTitle: {
    fontWeight: 500,
    fontSize: 34,
    lineHeight: "40.8px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  headerContainerSubTitle: {
    fontSize: 16,
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  headerContainerSubTitleIcon: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0 14.9px",
  },
  headerButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  printButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#F5F5F5 !important",
    borderColor: "#d2d0d0 !important",
  },
  downloadButtonStyle: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "#E0E0E0 !important",
  },
  tabs: {
    background: "#fff",
    color: "rgba(0, 0, 0, 0.6)",
    borderRadius: "4px",
    fontWeight: 700,
    "& .MuiTab-root.Mui-selected": {
      borderBottom: "1px solid #512879",
      color: "#512879",
    },
  },
});

const TabsComponent = ({ value, handleChange, tabLabels }) => {
  const tabStyles = useStyles1();

  return (
    <AppBar position="static" sx={{ boxShadow: "none", borderRadius: "4px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
        className={tabStyles.tabs}
      >
        {tabLabels.map((label, index) => (
          <Tab
            label={label}
            key={index}
            id={`full-width-tab-${index}`}
            area-aria-controls={`full-width-tabpanel-${index}`}
            sx={{ opacity: 1 }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default TabsComponent;
