import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
// import store from "./Store/store"; @ashnaz, please check
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/styles/app.scss";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { baseApi } from "./services/api";
import { GlobalNotification } from "./Common/GlobalNotification";

const theme = createTheme({
  palette: {
    primary: {
      main: "#512879",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#12830b",
      contrastText: "#ffffff",
    },
    success: {
      main: "#12830b",
    },
    error: {
      main: "#e8231b",
    },
    neutral: {
      main: "#E0E0E0",
      light: "#fff",
      dark: "#9E9E9E",
    },
    default: {
      main: "#F5F5F5",
    },
  },
  // typography: {
  //   fontFamily: '"Verlag", "Josefin Sans", sans-serif',
  //   h1: {
  //     fontFamily:
  //       '"Verlag Black Italic", "Josefin Sans Bold Italic", sans-serif',
  //     textTransform: "uppercase",
  //     fontWeight: 900, // Black weight
  //     fontStyle: "italic",
  //   },
  //   h2: {
  //     fontFamily:
  //       '"Verlag Bold Italic", "Josefin Sans SemiBold Italic", sans-serif',
  //     textTransform: "uppercase",
  //     fontWeight: 700, // Bold weight
  //     fontStyle: "italic",
  //   },
  //   body1: {
  //     fontFamily:
  //       '"Verlag Light Italic", "Josefin Sans Light Italic", sans-serif',
  //     fontWeight: 300, // Light weight
  //     fontStyle: "italic",
  //   },
  //   button: {
  //     fontFamily: '"Verlag Bold", "Josefin Sans Bold", sans-serif',
  //     textTransform: "uppercase",
  //     fontWeight: 700, // Bold weight for call-to-actions
  //   },
  //   subtitle1: {
  //     fontFamily:
  //       '"Verlag Book Italic", "Josefin Sans Regular Italic", sans-serif',
  //     fontWeight: 400, // Book weight for regular text
  //     fontStyle: "italic",
  //   },
  //   // Add more specific mappings as needed
  // },

  typography: {
    fontFamily: '"Verlag italic", "Josefin Sans", sans-serif',

    h1: {
      textTransform: "uppercase",
      fontFamily: '"Verlag", "Josefin Sans", sans-serif',
      fontStyle: "italic",
      fontWeight: "900 !important",
    },
    h2: {
      textTransform: "uppercase",
      fontFamily: '"Verlag", "Josefin Sans", sans-serif',
      fontStyle: "italic",
    },
    h3: {
      textTransform: "uppercase",
      fontFamily: '"Verlag", "Josefin Sans", sans-serif',
      fontStyle: "italic",
    },
    button: {
      textTransform: "uppercase",
      fontFamily: '"Verlag", "Josefin Sans", sans-serif',
      fontStyle: "italic",
    },
    // Add more headline types if needed
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#fff",
          color: "#b7b7b7",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        {/* <GlobalNotification /> */}
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
