import * as React from "react";
import { ButtonGroup, Button, Menu, MenuItem } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";
import { useStyles } from "../../Theme/Style";
import { Link, useNavigate } from "react-router-dom";
import { checkIsAdmin, checkPermission } from "../../helpers/helpers";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #512879",
    borderRadius: "5px 0px 5px 5px",
    minWidth: 180,
    color: "#512879",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& a": {
      textDecoration: "none !important",
    },
    "& .MuiMenu-list": {
      padding: "4px 0",
      textTransform: "uppercase",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      fontWeight: 500,
      color: "#512879 !important",

      "& .MuiSvgIcon-root": {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CustomerActionsButton = ({ ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { row, component } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [generateInvoiceLink] = React.useState(`/admin/invoices/${row.id}`);
  const [generateSalesOrderLink] = React.useState(
    `/admin/sales-orders/${row.id}`
  );
  const [editQuotation] = React.useState(`/admin/quotation/${row?.id}`);
  const open = Boolean(anchorEl);
  const showEditQuotation = checkPermission("Edit Quotations");
  const showCreateSalesOrder = checkPermission("Create Sales Orders");
  const showCreateInvoice = checkPermission("Create Invoice");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    if (component === "customerInvoice") {
      navigate(`/admin/invoice/${row.id}/customer`);
    } else if (component === "customerSalesOrder") {
      checkIsAdmin()
        ? navigate(`/admin/sales-order/proceed/${row.id}`)
        : navigate(`/sales-order/proceed/${row.id}`);
    } else if (component === "salesOrderInProductionOrders") {
      navigate(`/admin/production-orders/${row.id}/view`);
    } else if (component === "transaction") {
      navigate(`/admin/invoices/${row?.invoice_id}/view`);
    } else {
      navigate(`/admin/quotation/${row.id}/admin`);
    }
  };

  const handleProductOrder = () => {
    navigate(`/admin/production-orders/${row?.sales_order_id}`);
  };

  return (
    <div>
      <ButtonGroup
        aria-label="outlined primary button group"
        className={classes.outlinedGroupButton}
      >
        {component !== "transaction" ? (
          <Button onClick={handleView}>View</Button>
        ) : (
          <Button onClick={handleView}>Invoice View</Button>
        )}

        {row?.status && (
          <>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                backgroundColor:
                  Boolean(anchorEl) && row.status.keyword !== "pending"
                    ? "#512879 !important"
                    : undefined,
                color:
                  Boolean(anchorEl) && row.status.keyword !== "pending"
                    ? "#fff !important"
                    : undefined,
              }}
              disableElevation
              onClick={handleClick}
            >
              <ArrowDropDown />
            </Button>
            {component === "salesOrder" && row.status.keyword !== "pending" && (
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {showCreateInvoice && row.status.keyword === "approved" && (
                  <Link to={generateInvoiceLink}>
                    <MenuItem onClick={handleClose} disableRipple>
                      Generate Invoice
                    </MenuItem>
                  </Link>
                )}
                {row.status.keyword === "paid" && (
                  <MenuItem onClick={handleClose} disableRipple>
                    Send Production Order
                  </MenuItem>
                )}
              </StyledMenu>
            )}
          </>
        )}

        {row?.quotation_status && (
          <>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                backgroundColor:
                  Boolean(anchorEl) &&
                  row.quotation_status.keyword !== "submitted"
                    ? "#512879 !important"
                    : undefined,
                color:
                  Boolean(anchorEl) &&
                  row.quotation_status.keyword !== "submitted"
                    ? "#fff !important"
                    : undefined,
              }}
              disableElevation
              onClick={handleClick}
            >
              <ArrowDropDown />
            </Button>
            {showCreateSalesOrder &&
              row.quotation_status.keyword !== "submitted" && (
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {row.quotation_status.keyword === "accepted" && (
                    <Link to={generateSalesOrderLink}>
                      <MenuItem onClick={handleClose} disableRipple>
                        Generate Sales Order
                      </MenuItem>
                    </Link>
                  )}
                </StyledMenu>
              )}
            {showEditQuotation &&
              row.quotation_status.keyword === "rejected" && (
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <Link to={editQuotation}>
                    <MenuItem onClick={handleClose} disableRipple>
                      Edit
                    </MenuItem>
                  </Link>
                </StyledMenu>
              )}
          </>
        )}
      </ButtonGroup>
    </div>
  );
};
export default CustomerActionsButton;
