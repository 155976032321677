import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import GarmentImage from "../../GarmentImage/GarmentImage.component";
import styles from "./CartTotals.module.scss";

const CartItems = ({
  style,
  name,
  unitprice,
  deliveryMethod,
  itemTotal,
  quantity,
  id,
  thumbnail,
  organization_id,
}) => {
  return (
    <ListItem alignItems="flex-start" sx={{ px: 0 }}>
      <ListItemAvatar>
        <GarmentImage
          id={id}
          thumbnail={thumbnail}
          organizationId={organization_id}
          padding={0}
          minHeight={50}
          maxWidth={60}
          shadow={false}
        />
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          style && (
            <React.Fragment>
              <Typography
                sx={{ display: "inline-block", mr: 2 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Style #{style}
              </Typography>
            </React.Fragment>
          )
        }
      />
      <ListItemText
        sx={{ textAlign: "right" }}
        primary={
          itemTotal && (
            <React.Fragment>
              <Typography
                sx={{
                  display: "inline-block",
                  textAlign: "right",
                  color: "#512879",
                }}
                component="span"
                variant="subtitle"
              ></Typography>
              ${itemTotal}
            </React.Fragment>
          )
        }
        secondary={
          <React.Fragment>
            {quantity && (
              <>
                <Typography
                  sx={{ display: "inline-block", textAlign: "right" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                ></Typography>
                Qty: {quantity} <br />
              </>
            )}
            {deliveryMethod && (
              <>
                <Typography
                  variant="body2"
                  color="text.primary"
                  component="span"
                  sx={{
                    textTransform: "capitalize",
                  }}
                ></Typography>
                <span className={styles.deliveryMethod}>
                  {deliveryMethod.replaceAll("-", " ")}
                </span>
              </>
            )}
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

const CartTotals = (props) => {
  const { items, cartItemTotal, cartDeliveryTotal, cartITotal } = props;

  return (
    <>
      <List
        sx={{
          width: "100%",
        }}
      >
        {items.map((item) => (
          <div key={item?.id}>
            <CartItems
              name={item?.name}
              style={item?.style}
              unitprice={item?.unitprice}
              deliveryMethod={item?.deliveryMethod}
              itemTotal={item?.itemTotal}
              quantity={item?.quantity}
              id={item?.id}
              thumbnail={item?.thumbnail}
              organization_id={item?.organization_id}
            />
            <Divider variant="inset" component="li" sx={{ px: 0, ml: 1 }} />
          </div>
        ))}

        <Box sx={{ px: 0, mt: 3 }}>
          {cartItemTotal && (
            <Typography color="text.secondary" gutterBottom variant="body2">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <span>Subtotal</span>
                <span style={{ flex: 1 }}></span>
                <strong>{cartItemTotal}</strong>
              </Stack>
            </Typography>
          )}

          {cartDeliveryTotal && (
            <Typography color="text.secondary" gutterBottom variant="body2">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <span>Delivery</span>
                <span style={{ flex: 1 }}></span>
                <strong>${cartDeliveryTotal}</strong>
              </Stack>
            </Typography>
          )}

          {cartITotal && (
            <Typography
              color="text.primary"
              gutterBottom
              variant="h6"
              sx={{ mt: 1, fontSize: "1rem" }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <span>Total</span>
                <span style={{ flex: 1 }}></span>
                <strong>${cartITotal}</strong>
              </Stack>
            </Typography>
          )}
        </Box>
      </List>
    </>
  );
};

export default CartTotals;
