import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import GarmentImage from "../GarmentImage/GarmentImage.component";

const GarmentItem = (props) => {
  const { id, poId, name, unitPrice, style, thumbnail, organization_id } =
    props;

  const [hover, setHover] = useState(false);

  return (
    <Box>
      <Card
        elevation={0}
        sx={{ p: 1, textAlign: "center", backgroundColor: "transparent" }}
      >
        <Link
          to={`/store/${id}`}
          style={{ position: "relative", display: "block" }}
        >
          <GarmentImage
            id={poId}
            thumbnail={thumbnail}
            organizationId={organization_id}
            setHover={setHover}
          />

          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              mx: "auto",
              bottom: hover ? 30 : 0,
              opacity: hover ? 1 : 0,
              transition: "all 0.3s ease-in-out",
              width: "90%",
              maxWidth: 120,
              pointerEvents: "none",
            }}
          >
            Order Item
          </Button>
        </Link>

        <CardContent>
          {name && (
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ fontSize: "1rem", fontWeight: 600, mb: 0 }}
            >
              {name}
            </Typography>
          )}

          {style && (
            <Typography variant="caption" color="text.secondary">
              Style #{style}
            </Typography>
          )}

          {unitPrice && (
            <Typography variant="subtitle" component="div" sx={{ mt: 2 }}>
              <strong style={{ color: "#512879" }}>${unitPrice}</strong>{" "}
              <small style={{ fontSize: "70%" }}>Per item</small>
            </Typography>
          )}
        </CardContent>
      </Card>
      {/*  */}
    </Box>
  );
};

export default GarmentItem;
