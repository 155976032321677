import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Box,
  Button,
  InputBase,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StatusChange from "../SalesOrders/StatusChange";
import ActionsButton from "../SalesOrders/ActionsButton";
import moment from "moment";
import { authHeader } from "../../helpers";
import { apiConstants } from "../../constants";
import { useEffect } from "react";

const useStyles = makeStyles({
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
  filterButton: {
    fontSize: 15,
    color: "#fff !important",
    height: "42px",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#512879 !important",
      border: "1px solid #512879",
    },
  },
  dataGrid: {
    "& .MuiTablePagination-displayedRows": {
      fontSize: 12,
    },
    "& .MuiTablePagination-select": {
      fontSize: 12,
    },
    "& .MuiTablePagination-selectLabel": {
      fontSize: 12,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
  },
  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const columns = [
  { field: "id", headerName: "Production ID", resizable: false },
  {
    field: "created_at",
    headerName: "Date",
    width: 125,
    renderCell: (params) => moment(params.row.created_at).format("DD/MM/YYYY"),
  },
  {
    field: "item_name",
    headerName: "Style",
    sortable: true,
    width: 200,
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => `${params.row.price?.item?.name}`,
  },
  {
    field: "fabric_name",
    headerName: "Fabric",
    width: 200,
    minWidth: 100,
    valueGetter: (params) => `${params.row.price?.fabric?.name}`,
  },
  // {
  //   field: "color",
  //   headerName: "Color",
  //   width: 125,
  //   minWidth: 100,
  //   valueGetter: (params) => `${params.row.items[0]?.color?.english_name}`,
  // },
  // {
  //   field: "quantity",
  //   headerName: "Quantity",
  //   resizable: false,
  //   valueGetter: (params) => `${params.row.items[0]?.qty}`,
  // },
  {
    field: "organization_name",
    headerName: "Organization Name",
    sortable: true,
    width: 200,
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => `${params.row.organization?.name || ""}`,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => (
      <StatusChange {...params} component="productionOrders" />
    ),
    width: 125,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 125,
    type: "actions",
    renderCell: (params) => (
      <ActionsButton {...params} component="allProductionOrderItem" />
    ),
    resizable: false,
  },
];

const StyledInputBase = styled(InputBase)(() => ({
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em)`,
    width: "100%",
    height: "38px",
  },
}));

const AllProductionOrders = ({ ...props }) => {
  const {
    productionOrders,
    pendingProductionOrders,
    completeProductionOrders,
    approveProductionOrders,
    status,
    paginationData,
    // handlePageChange,
    loadingTabComponent,
  } = props;

  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [filteredResults, setFilteredResults] = React.useState([]);

  const [searchCustomer, setSearchCustomer] = React.useState("");
  const [filterItemName, setFilterItemName] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    setLoading(true);

    fetch(`${apiConstants.API_URL}/production-orders`, requestOptions).then(
      (response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setFilteredResults(data?.data);
          setResponse(data?.meta);
          setLoading(false);
        });
      }
    );

    if (status === 0) {
      setRows(productionOrders);
      setFilteredResults(productionOrders);
    } else if (status === 1) {
      setRows(pendingProductionOrders);
    } else if (status === 2) {
      setRows(completeProductionOrders);
    } else if (status === 3) {
      setRows(approveProductionOrders);
    }
    if (searchCustomer === "") {
      setFilteredResults(rows);
    }
    if (filterItemName === "") {
      setFilteredResults(rows);
    }
  }, [
    status,
    productionOrders,
    pendingProductionOrders,
    completeProductionOrders,
    approveProductionOrders,
    rows,
  ]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    if (searchCustomer === "") {
      fetch(
        `${apiConstants.API_URL}/production-orders?page=1`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setFilteredResults(data?.data);
          setResponse(data?.meta);
        });
      });
    }
  }, [searchCustomer]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    if (filterItemName === "") {
      fetch(
        `${apiConstants.API_URL}/production-orders?page=1`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setFilteredResults(data?.data);
          setResponse(data?.meta);
        });
      });
    }
  }, [filterItemName]);

  const getItemName = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/production-orders?id=${filterItemName}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFilteredResults(data?.data);
        setResponse(data?.meta);
      });
    });
  };

  const getOrganization = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${apiConstants.API_URL}/production-orders?organization_name=${searchCustomer}`,
      requestOptions
    ).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFilteredResults(data?.data);
        setResponse(data?.meta);
      });
    });
  };

  const handleFilter = () => {
    setFilterItemName("");
    if (searchCustomer !== "") {
      getOrganization();
    } else {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(
        `${apiConstants.API_URL}/production-orders?page=${currentPage + 1}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setFilteredResults(data?.data);
          setResponse(data?.meta);
        });
      });
    }
  };

  const handleItemNameFilter = () => {
    setSearchCustomer("");
    if (filterItemName !== "") {
      getItemName();
    } else {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(
        `${apiConstants.API_URL}/production-orders?page=${currentPage + 1}`,
        requestOptions
      ).then((response) => {
        response.text().then((text) => {
          const data = text && JSON.parse(text);
          setFilteredResults(data?.data);
          setResponse(data?.meta);
        });
      });
    }
  };
  // ${apiConstants.API_URL}/production-orders?page=${number}
  function handlePageChange(page) {
    setCurrentPage(page);
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    let url;
    if (filterItemName != "" && searchCustomer == "") {
      url = `${
        apiConstants.API_URL
      }/production-orders?id=${filterItemName}&page=${page + 1}`;
    } else if (filterItemName == "" && searchCustomer != "") {
      url = `${
        apiConstants.API_URL
      }/production-orders?organization_name=${searchCustomer}&page=${page + 1}`;
    } else {
      url = `${apiConstants.API_URL}/production-orders?page=${page + 1}`;
    }

    fetch(url, requestOptions).then((response) => {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        setFilteredResults(data?.data);
        setResponse(data?.meta);
      });
    });
  }

  return (
    <>
      <Box className={classes.salesOrdersFilterBar}>
        <Typography
          component={"span"}
          className={classes.salesOrderFilterTitle}
        >
          Filter Production Orders
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box className={classes.salesOrderFilterContainer}>
            <Box sx={{ mr: 2 }}>
              <StyledInputBase
                placeholder="Style"
                inputProps={{ "aria-label": "search", type: "text" }}
                onChange={(e) => {
                  setFilterItemName(e.target.value);
                }}
                value={filterItemName}
                onClick={() => setSearchCustomer("")}
              />
            </Box>
            <Button
              variant="contained"
              className={classes.filterButton}
              onClick={handleItemNameFilter}
            >
              filter
            </Button>
          </Box>
          <Box className={classes.salesOrderFilterContainer}>
            <Box sx={{ mr: 2 }}>
              <StyledInputBase
                placeholder="Customer Name"
                inputProps={{ "aria-label": "search", type: "text" }}
                onChange={(e) => {
                  setSearchCustomer(e.target.value);
                }}
                value={searchCustomer}
                onClick={() => setFilterItemName("")}
              />
            </Box>
            <Button
              variant="contained"
              className={classes.filterButton}
              onClick={handleFilter}
            >
              filter
            </Button>
          </Box>
        </Box>
      </Box>
      <Box style={{ width: "100%" }}>
        {loadingTabComponent || loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : (
          <DataGrid
            autoHeight={true}
            className={classes.dataGrid}
            rows={filteredResults}
            pageSize={response?.per_page}
            pagination
            getRowId={(row) => row.id}
            checkboxSelection
            rowCount={response?.total}
            onPageChange={handlePageChange}
            columns={columns}
            paginationMode="server"
          />
        )}
      </Box>
    </>
  );
};

export default AllProductionOrders;
