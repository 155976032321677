import moment from "moment";
import * as React from "react";

import ViewTrackingDetails from "../../../Common/ViewTrackingDetails";
import { convertToPlaces } from "../../../helpers/helpers";
import { InvoicesActionButton } from "../../atoms/ActionButton/InvoicesActionButton";
import CustomGrid from "../../atoms/CustomGrid/CustomGrid";
import InvoiceStatusLable from "../../atoms/StatusLable/InvoiceStatusLable";

const createIdList = (ids) => {
  return (
    <>
      {ids.map((id, index) => (
        <>
          <div>{id}</div>
          {ids.length - 1 !== index && <span>,</span>}
        </>
      ))}
    </>
  );
};

const salesOrderIds = (params) => {
  if (!params?.row?.sales_order_items) return "-";

  const salesOrderIds = params?.row?.sales_order_items.map((item, index) => {
    return item?.sales_order_id;
  });
  const uniqIds = [...new Set(salesOrderIds)];
  return createIdList(uniqIds);
};

const productionOrderIds = (params) => {
  if (!params?.row?.sales_order_items) return "-";

  const ids = params?.row?.sales_order_items?.map((item) => {
    if (
      item?.production_order_id !== undefined ||
      item?.production_order_id !== null
    ) {
      return item?.production_order_id;
    }
  });
  const uniqIds = [...new Set(ids)];
  return createIdList(uniqIds);
};

export const InvoicesTable = ({
  invoices,
  perPageCount,
  totalRowsConunt,
  handlePageChange,
  pathToView,
  paymentTerms,
  pages,
  setPages,
}) => {
  const getPaymentTerm = (id) => {
    const pt = paymentTerms?.filter((term) => term?.id === id);
    return pt && pt[0]?.name;
  };

  const columns = [
    { field: "id", headerName: "Invoice ID", resizable: false },
    {
      field: "created_at",
      headerName: "Date",
      // width: 115,
      renderCell: (params) =>
        moment(params.row.created_at).format("DD/MM/YYYY"),
    },
    {
      field: "sales_order_id",
      headerName: "Sales Order ID",
      renderCell: (params) => salesOrderIds(params),
    },
    {
      field: "production_order_id",
      headerName: "Production Order ID",
      minWidth: 150,
      renderCell: (params) => productionOrderIds(params),
    },
    {
      field: "invoice_courier_details",
      headerName: "Tracking Number",
      // width: 120,
      renderCell: (params) => (
        <ViewTrackingDetails
          {...params}
          column={"trackingNumber"}
          handlePageChange={handlePageChange}
        />
      ),
    },
    {
      field: "destination",
      headerName: "Destination",
      minWidth: 150,
      renderCell: (params) => (
        <ViewTrackingDetails {...params} column={"destination"} />
      ),
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      sortable: true,
      minWidth: 150,
      valueGetter: (params) => `${params.row.organization?.name || ""}`,
    },
    {
      field: "final_grand_total",
      headerName: "Balance Outstanding",
      sortable: true,
      // minWidth: 150,
      valueGetter: (params) =>
        `${convertToPlaces(params.row.balance_amount) || ""}`,
    },
    {
      field: "payment_term",
      headerName: "Payment Term",
      sortable: true,
      // minWidth: 150,
      valueGetter: (params) =>
        `${getPaymentTerm(params.row.organization?.payment_term_id) || ""}`,
    },
    {
      field: "total",
      headerName: "Total",
      // width: 100,
      valueGetter: (params) =>
        `${convertToPlaces(params.row.grand_total) || ""}`,
    },
    {
      field: "status",
      headerName: "Status",
      // width: 100,
      renderCell: (params) => (
        <InvoiceStatusLable {...params} component="invoice" />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      // width: 100,
      type: "actions",
      renderCell: (params) => (
        <InvoicesActionButton
          pathToView={pathToView}
          invoiceId={params.row.id}
        />
      ),
    },
  ];

  return (
    <CustomGrid
      rows={invoices}
      columns={columns}
      perPageCount={perPageCount}
      totalRowCount={totalRowsConunt}
      handlePageChange={handlePageChange}
      pages={pages}
      setPages={setPages}
      invoiceExport={true}
    />
  );
};
