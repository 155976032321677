import { FilterAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputBase,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { InvoicesTable } from "./InvoicesTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const StyledInputBase = styled(InputBase)(() => ({
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.06)",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em)`,
    width: "100%",
    height: "38px",
    // width: "100%",
  },
}));

const useStyles = makeStyles({
  salesOrdersFilterBar: {
    margin: "14px 0px 16px",
    backgroundColor: "#EFEFEF",
    padding: "16px",
  },
  filterButton: {
    fontSize: 15,
    color: "#fff !important",
    height: "42px",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#512879 !important",
      border: "1px solid #512879",
    },
  },
  dataGrid: {
    "& .MuiTablePagination-displayedRows": {
      fontSize: 12,
    },
    "& .MuiTablePagination-select": {
      fontSize: 12,
    },
    "& .MuiTablePagination-selectLabel": {
      fontSize: 12,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
  },
  salesOrderFilterTitle: {
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14 !important",
  },
  salesOrderFilterContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 10px",
  },
});

export const InvoicesTableWithFilter = ({
  enableFilters,
  invoicesViewLink,
  getInvoiceByOrganization,
  getInvoiceByPOId,
  getInvoiceByDate,
  handlePageChange,
  getInvoiceByInvoiceId,
  searchCustomer,
  setSearchCustomer,
  setFilterByPOId,
  setFilterByDates,
  invoices,
  paginationData,
  setFilterByInvoiceId,
  filterByInvoiceId,
  filterByPOId,
  filterByDates,
  paymentTerms,
  pages,
  setPages,
}) => {
  const classes = useStyles();

  const isMobile = useMediaQuery("(max-width:1000px)");

  return (
    <>
      <Box className={classes.salesOrdersFilterBar}>
        {enableFilters && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              ...(isMobile && {
                flexDirection: "column",
              }),
            }}
          >
            <Box className={classes.salesOrderFilterContainer}>
              <Box>
                <StyledInputBase
                  placeholder="Customer Name"
                  inputProps={{ "aria-label": "search", type: "text" }}
                  value={searchCustomer}
                  onChange={(e) => {
                    setSearchCustomer(e.target.value);
                  }}
                  onClick={() => {
                    setFilterByInvoiceId("");
                    setFilterByPOId("");
                    setFilterByDates({ start_date: null, end_date: null });
                  }}
                />
              </Box>
              <Button
                variant="contained"
                className={classes.filterButton}
                onClick={getInvoiceByOrganization}
              >
                <FilterAlt fontSize="12" />
              </Button>
            </Box>
            <Box className={classes.salesOrderFilterContainer}>
              <Box>
                <StyledInputBase
                  placeholder="Invoice ID"
                  inputProps={{ "aria-label": "search", type: "text" }}
                  value={filterByInvoiceId}
                  onChange={(e) => {
                    setFilterByInvoiceId(e.target.value);
                  }}
                  onClick={() => {
                    setSearchCustomer("");
                    setFilterByPOId("");
                    setFilterByDates({ start_date: null, end_date: null });
                  }}
                />
              </Box>
              <Button
                variant="contained"
                className={classes.filterButton}
                onClick={getInvoiceByInvoiceId}
              >
                <FilterAlt fontSize="12" />
              </Button>
            </Box>

            {/* prod order id */}
            <Box className={classes.salesOrderFilterContainer}>
              <Box>
                <StyledInputBase
                  placeholder="Production Order ID"
                  inputProps={{ "aria-label": "search", type: "text" }}
                  value={filterByPOId}
                  onChange={(e) => {
                    setFilterByPOId(e.target.value);
                  }}
                  onClick={() => {
                    setSearchCustomer("");
                    setFilterByInvoiceId("");
                    setFilterByDates({ start_date: null, end_date: null });
                  }}
                />
              </Box>
              <Button
                variant="contained"
                className={classes.filterButton}
                onClick={getInvoiceByPOId}
              >
                <FilterAlt fontSize="12" />
              </Button>
            </Box>

            {/* date range picker */}
            <Box className={classes.salesOrderFilterContainer}>
              <Stack
                style={{
                  marginRight: "20px",
                }}
              >
                <DatePicker
                  label="Start Date"
                  id="start-date"
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                  value={dayjs(filterByDates.start_date)}
                  onChange={(newValue) => {
                    setFilterByDates({
                      ...filterByDates,
                      start_date: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
                    });
                  }}
                />
                <DatePicker
                  label="End Date"
                  id="end-date"
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                  value={dayjs(filterByDates.end_date)}
                  onChange={(newValue) => {
                    setFilterByDates({
                      ...filterByDates,
                      end_date: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
                    });
                  }}
                />
              </Stack>
              <Button
                variant="contained"
                className={classes.filterButton}
                onClick={getInvoiceByDate}
              >
                <FilterAlt fontSize="12" />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box style={{ width: "100%" }}>
        <InvoicesTable
          invoices={invoices}
          perPageCount={paginationData?.per_page}
          totalRowsConunt={paginationData?.total}
          handlePageChange={handlePageChange}
          pathToView={invoicesViewLink}
          paymentTerms={paymentTerms}
          pages={pages}
          setPages={setPages}
        />
      </Box>
    </>
  );
};
